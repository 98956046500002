import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { Title, Section, Box, Text, Span } from "../../components/Core";
import { device } from "../../utils";
import trust from "../../assets/image/png/trust.svg";


const SecondText = styled.h3`
  font-size: 2.2em;
  margin-bottom: 20px;
  color: #1a1a1a;
  font-weight: 200;
  letter-spacing: 2px;

  @media screen and (max-width: 767px) {
    margin-bottom: 24px;
    font-size: 16px;

    margin: 0 auto;
 
  }
`;


const ContentCard = ({ iconImage, title, className, children, ...rest }) => (
  <Box
    {...rest}
    className={`d-flex align-items-start justify-content-start ${className}`}
  >
    <Box mr={4}>
      <img src={iconImage} alt="" />
    </Box>
    <Box>
      <Title variant="card" fontSize="24px" mb={3}>
        {title}
      </Title>
      <Text variant="small">{children}</Text>
    </Box>
  </Box>
);

const ImgStyled = styled.img`

  box-shadow: ${({ theme }) => `0 52px 54px ${theme.colors.shadow}`};
  border-radius: 10px;
  max-width: 100%;
  @media ${device.sm} {
    max-width: 50%;
  }
  @media ${device.md} {
    max-width: 33%;
  }
  @media ${device.lg} {
    max-width: 100%;
  }
  @media ${device.xl} {
    
  }
`;

const TearUnder = () => (
  <>
    {/* <!-- Content section 2 --> */}
    <Section py={4}>
      <Container className="pb-3 pt-3">

          <Row>
              <Col>
             
      <SecondText className="pb-4">
    Are Tear Trough Treatments Safe?

      </SecondText>

   
      <Text>
            The idea of having a treatment done under the eye can cause uncertainty for many, especially as it is a complex area. Therefore, it is important to understand the risks and complications involved, and what fillers are safe to use under the eyes. Most importantly, tear trough treatments should only be undertaken and treated by experienced practitioners.
            
            <br /><br />   The dermal filler specially used for the under eye is made of hyaluronic acid, which is a natural substance that already exists in the human body. When inserted into our bodies, the filler increases fullness and decreases indentions under the eye. The benefit of hyaluronic acid is that it can be easily dissolved with a natural enzyme called hyaluronidase (also found in our bodies). This can be used to dissolve fillers for those that do not like the result or want to lessen the amount under the eyes. If severe complications occur, the filler can be dissolved to prevent further risks. <br /><br />  
            As the under eye area is intricate, it carries the same risk of necrosis as your lips, and this occurs when the injection accidently goes into an artery, causing premature death of . Other complication and side effects of the treatment can include headache, allergic reaction, edema (swelling), filler forming into lumps, vascular side effects and in very rare cases blindness due to central retinal artery occlusion. This is very rare in the cosmetic field and so it is important to make sure you are in safe and experienced hands.
            </Text>

            <br />
              <Text><i><b>"The consultation with Mina has been the most refreshing and personal...She s an absolutely delightful doctor and lady that makes you feel comfortable straight away.I walked in to share the area of concern that I wanted to treat ( tear through and cheeks enhancement) and she gave very honest options,without pushing to buy packages or adding treatments,as many other clinics would do." - Simona</b></i>
              <a href="https://uk.trustpilot.com/review/www.dermamina.com" target="_blank"> <img src={trust} width="150px" /></a> <br /> <br />
                </Text>



                <SecondText className="pb-4">
Tear Trough Filler Cost UK

      </SecondText>

                <Text>
            Tear trough cost starts from £450 and you can get this done straight after your consultation. 

            </Text><br />

            <Text>
              So what makes Dermamina so popular with tear trough in London? Well, for one we have completed thousands of tear trough treatments with results being amazing: 
              </Text><br />
              <Text><i><b>"I had tear trough treatment today and everything was amazing , from the consultation to the end result !" - Alla</b></i>
              <a href="https://uk.trustpilot.com/review/www.dermamina.com" target="_blank"> <img src={trust} width="150px" /></a>
                </Text><br />
             
            
              <Text>With such great results we have been told over and over by our clients that we are the best tear trough clinic in London, if not the UK: </Text><br />
              <Text><i><b>"I visited the salon for Tear trough and other treatments, the atmosphere in the salon is very pleasant and friendly, I am satisfied with the result." - Lena</b></i>
              <a href="https://uk.trustpilot.com/review/www.dermamina.com" target="_blank"> <img src={trust} width="150px" /></a>

              </Text>

              </Col>
          </Row>
        
          
       
            
            
      </Container>
    </Section>
  </>
);

export default TearUnder;




          
        
         
