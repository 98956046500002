import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import Slider from "react-slick"
import styled, { keyframes } from "styled-components"
import { Title, Section, Box, Text, Span } from "../../components/Core"
import { breakpoints } from "../../utils"
import imgC1 from "../../assets/image/jpeg/tear1.jpg"
import imgC3 from "../../assets/image/jpeg/tear3.jpg"
import imgC4 from "../../assets/image/jpeg/tear4.jpg"
import imgC6 from "../../assets/image/jpeg/tear6.jpg"
import imgC7 from "../../assets/image/jpeg/tear7.jpg"
import imgC8 from "../../assets/image/jpeg/tear8.jpg"
import imgC9 from "../../assets/image/jpeg/tear9.jpg"
import imgC10 from "../../assets/image/jpeg/tear10.jpg"
import imgC12 from "../../assets/image/jpeg/tear200.jpg"

const Separator = styled.div`
  position: relative;
  width: 100%;
  height: 10px;
  background-color: #ffffff;

  ::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background-color: #f7f7fb;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }
`

const SeparatorEnd = styled.div`
  position: relative;
  width: 100%;
  height: 10px;
  background-color: #f7f7fb;

  ::before,
  ::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
  }

  ::before {
    top: 0;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }

  ::after {
    bottom: 0;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }
`

const Iwrap = styled.div`
  display: flex;
  margin-bottom: 1em;
  align-items: center;

  @media screen and (max-width: 767px) {
    margin-bottom: 8px;
    justify-content: flex-start;
  }

  @media screen and (max-width: 991px) {
    margin-bottom: 8px;
    justify-content: center;
    text-align: center;
  }
`

const Itext = styled.div`
  margin-top: 0px;
  margin-bottom: 5px;
  color: #1a1a1a;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;

  span {
    color: #00aec7;
  }

  @media screen and (max-width: 991px) {
    font-size: 12px;
  }
`

const SecondText = styled.h3`
  font-size: 2.2em;
  margin-bottom: 20px;
  color: #1a1a1a;
  font-weight: 200;
  letter-spacing: 2px;

  @media screen and (max-width: 767px) {
    margin-bottom: 24px;
    font-size: 16px;
    align-items: center;
    margin: 0 auto;
    justify-content: center;
    text-align: center;
  }
`


const arrowAnimation = keyframes`
  0% { transform: translateX(0); }
  50% { transform: translateX(10px); }
  100% { transform: translateX(0); }
`

const Arrow = styled.i`
  margin-left: 10px;
  font-size: 1em;
  animation: ${arrowAnimation} 1.5s infinite;
  color: #00aec7;

  @media screen and (max-width: 767px) {
    font-size: 0.8em;
  }
`

const SwipeTextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1em;
  padding-bottom: 0px;
  color: #1a1a1a;
  font-weight: 300;
  margin-top: 1em;

  @media screen and (max-width: 767px) {
    font-size: 0.8em;
  }
`

const SliderStyled = styled(Slider)`
  .slick-dots {
    position: relative;
    margin-top: 10px;
    li {
      font-size: 0;
      width: 17px;
      height: 8px;
      border-radius: 4px;
      background-color: rgb(171, 142, 102, 0.3);
      margin-left: 5px;
      margin-right: 5px;
      transition: 0.5s;
      &.slick-active {
        width: 45px;
        height: 8px;
        border-radius: 4px;
        background-color: rgb(171, 142, 102);
      }
      button {
        width: 100%;
        height: 100%;
        &:before {
          content: none;
        }
      }
    }
  }

  .slick-slide {
    transition: transform 0.3s ease, filter 0.3s ease;
    padding: 0 10px; /* Add padding between slides */
    &:not(.slick-center) {
      transform: scale(1);
      @media screen and (max-width: 767px) {
        filter: grayscale(100%);
        transform: scale(0.8);
      }
    }
  }

  .slick-center {
    transform: scale(1.2);
  }
`

const ContentCard = ({
  className,
  image,
  name,
  company,
  children,
  ...rest
}) => (
  <Box
    mx="3px"
    className={`${className}`}
    {...rest}
    css={`
      &:focus {
        outline: none;
      }
      margin: 0 5px; /* Add margin to create gaps between items */
    `}
  >
    <Text color="dark" mb={4}>
      {children}
    </Text>
    <Box className={`d-flex justify-content-between`}>
      <Box className="d-flex justify-content-center align-items-start" mr={0}>
        <img src={image} alt="Non Surgical Nose Job" className="img-fluid" />
      </Box>
      <div className="flex-grow-1">
        <Title variant="card" mb={0}>
          {name}
        </Title>
        <Text fontSize={2}>{company}</Text>
      </div>
    </Box>
  </Box>
)

const TearGalleryPage = () => {
  const slickSettings = {
    dots: false,
    infinite: true,
    arrows: false,
    autoplay: true,

    centerMode: false,
    slidesToShow: 8,
    slidesToScroll: 1,
    speed: 500,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: breakpoints.md,
        settings: {
          slidesToShow: 1,
          infinite: true,
          centerMode: true,
          autoplay: false,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: "20%" /* Adjusted to account for margins */,
        },
      },
    ],
  }

  return (
    <>
      <Separator />

      <Section py={4} bg="#f7f7fb">
        <Container className="pt-3 pb-3">
          <Row className="align-items-center pb-4">
            <Col md="9" lg="12" className="">
              <Iwrap>
                <Itext as="h2">
                  Tear Trough <span>Before and After</span>
                </Itext>
              </Iwrap>
              <SecondText>See what is possible</SecondText>
            </Col>
          </Row>

          <Row>
            <Col
              lg="12"
              data-aos="fade-up"
              data-aos-duration="500"
              data-aos-once="false"
              data-aos-delay="500"
            >
              <SliderStyled {...slickSettings}>
                <Box
                  css={`
                    &:focus {
                      outline: none;
                    }
                  `}
                >
                  <ContentCard image={imgC10}></ContentCard>
                </Box>

                <Box
                  css={`
                    &:focus {
                      outline: none;
                    }
                  `}
                >
                  <ContentCard image={imgC12}></ContentCard>
                </Box>

                <Box
                  css={`
                    &:focus {
                      outline: none;
                    }
                  `}
                >
                  <ContentCard image={imgC1}></ContentCard>
                </Box>

                <Box
                  css={`
                    &:focus {
                      outline: none;
                    }
                  `}
                >
                  <ContentCard image={imgC3}></ContentCard>
                </Box>
                <Box
                  css={`
                    &:focus {
                      outline: none;
                    }
                  `}
                >
                  <ContentCard image={imgC4}></ContentCard>
                </Box>

                <Box
                  css={`
                    &:focus {
                      outline: none;
                    }
                  `}
                >
                  <ContentCard image={imgC6}></ContentCard>
                </Box>
                <Box
                  css={`
                    &:focus {
                      outline: none;
                    }
                  `}
                >
                  <ContentCard image={imgC7}></ContentCard>
                </Box>
                <Box
                  css={`
                    &:focus {
                      outline: none;
                    }
                  `}
                >
                  <ContentCard image={imgC8}></ContentCard>
                </Box>
                <Box
                  css={`
                    &:focus {
                      outline: none;
                    }
                  `}
                >
                  <ContentCard image={imgC9}></ContentCard>
                </Box>
                <Box
                  css={`
                    &:focus {
                      outline: none;
                    }
                  `}
                >
                  <ContentCard image={imgC10}></ContentCard>
                </Box>
              </SliderStyled>
            </Col>
          </Row>
          <SwipeTextContainer>
            Swipe
            <Arrow className="fas fa-arrow-right" />
          </SwipeTextContainer>
        </Container>
      </Section>
      <SeparatorEnd />
    </>
  )
}

export default TearGalleryPage
